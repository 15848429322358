import styled from 'styled-components';
import { accent, primaryFont, secondaryFont, black } from 'constants/theme';

export const Container = styled.div`
  padding: 0 4rem;
  margin: 0 auto;
`;

export const Main = styled.main``;

export const ContentBox = styled.div`
  max-width: 960px;
  margin: 0 auto;
`;

export const Content = styled.div`
  color: ${black};
  font-family: ${secondaryFont};

  padding-bottom: 4rem;
  p {
    line-height: 1.5;
    color: ;
  }

  strong {
    font-weight: 600;
  }

  h1,
  h2,
  h3 {
    font-weight: 400;
  }

  h2 {
    font-size: 2rem;
    margin: 0 auto 2rem;
  }

  ul li {
    list-style: circle;
    padding-left: 1rem;
    margin-bottom: 1rem;
  }

  ul {
    padding-left: 1.5rem;
  }

  .gatsby-resp-image-wrapper {
    margin-left: 0 !important;
  }
`;

export const CallToAction = styled.section`
  width: 100%;
  background-color: ${accent};
  padding: 4rem 0;
`;

export const CallToActionTitle = styled.h3`
  font-size: 2.2rem;
  line-height: 3.2rem;
  color: ${black};
  font-family: ${primaryFont};
  font-weight: 600;
`;

export const CallToActionDetails = styled.div`
  font-size: 2rem;
  line-height: 2rem;
  color: ${black};
  font-family: ${primaryFont};
  font-weight: 400;
  margin: 2rem 0;
`;
