import React from 'react';
import { Container, Link, Separator } from './breadcrumbs.css';

const Breadcrumbs = ({ links }) => {
  return (
    <Container>
      {links.map((link, index) => {
        return (
          <>
            <Link key={index} to={link.url}>
              {link.text}
            </Link>
            {index !== links.length - 1 ? (
              <Separator key={`${index}-separator`}>/</Separator>
            ) : null}
          </>
        );
      })}
    </Container>
  );
};

export default Breadcrumbs;
