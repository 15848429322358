import styled from 'styled-components';
import { Link as _Link } from 'gatsby';

export const Container = styled.div`
  margin: 3rem auto;
`;

export const Separator = styled.span`
  display: inline-block;
  margin: 0 0.5rem;
`;

export const Link = styled(_Link)`
  color: black;
  display: inline-block;
  white-space: nowrap;
  margin: 0.75rem 0;
`;
