import React from 'react';
import Layout from 'components/layout';
import propTypes from 'prop-types';
import { graphql } from 'gatsby';
import {
  Main,
  Content,
  CallToAction,
  CallToActionTitle,
  CallToActionDetails,
} from './service.css';
import {
  Title,
  Container,
  ContentBox,
  TitleContainer,
} from 'components/common';
import Breadcrumbs from 'components/breadcrumbs';

const Service = ({ pageContext, data, location }) => {
  const { companyInfo } = data.site.siteMetadata;
  return (
    <Layout>
      <Main>
        <Container>
          <ContentBox>
            <TitleContainer>
              <Title>{pageContext.title}</Title>
              <Breadcrumbs
                links={[
                  { text: 'Strona główna', url: '/' },
                  { text: 'Oferta', url: '/oferta' },
                  { text: pageContext.title, url: location.pathname },
                ]}
              />
            </TitleContainer>
            <Content
              dangerouslySetInnerHTML={{ __html: pageContext.content }}
            />
          </ContentBox>
        </Container>
        <CallToAction>
          <Container>
            <ContentBox>
              <CallToActionTitle>
                Jesteś zainteresowany, chcesz wycenić projekt, potrzebujesz
                porady? Zadzwoń lub napisz do nas:
              </CallToActionTitle>
              <CallToActionDetails>
                Telefon: {companyInfo.phone}
              </CallToActionDetails>
              <CallToActionDetails>
                Adres email: {companyInfo.email}
              </CallToActionDetails>
            </ContentBox>
          </Container>
        </CallToAction>
      </Main>
    </Layout>
  );
};

export default Service;

Service.propTypes = {
  pageContext: propTypes.shape({
    title: propTypes.string,
    content: propTypes.string,
  }),
  data: propTypes.shape({
    site: propTypes.shape({
      siteMetadata: propTypes.shape({
        companyInfo: propTypes.shape({
          phone: propTypes.string,
          email: propTypes.string,
        }),
      }),
    }),
  }),
  location: propTypes.object,
};

export const query = graphql`
  query ServicePageQuery {
    site {
      siteMetadata {
        companyInfo {
          phone
          email
        }
      }
    }
  }
`;
